import React, { ReactElement } from "react";

export default function RocketIcon({
    className,
}: {
    className?: string;
}): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="44"
            viewBox="0 0 32 44"
            className={className ?? ""}
        >
            <path
                fill="#FE895C"
                d="M26.075 4.974A23.5 23.5 0 0117.59.9l-.858-.654a1.21 1.21 0 00-1.466 0l-.856.65A23.486 23.486 0 015.928 4.97c-.546.137-.93.633-.928 1.201v7.647a14.632 14.632 0 005.75 11.612l4.517 3.421a1.21 1.21 0 001.466 0l4.523-3.421A14.631 14.631 0 0027 13.818V6.171c0-.566-.381-1.06-.925-1.197z"
            ></path>
            <path
                fill="#FE895C"
                d="M10.057 7.842h11.886C27.497 7.842 32 12.344 32 17.899H0C0 12.344 4.503 7.842 10.057 7.842z"
            ></path>
            <ellipse
                cx="16"
                cy="13.007"
                fill="#FFF"
                rx="4"
                ry="4.023"
            ></ellipse>
            <path
                fill="#FE895C"
                d="M12.028 24.07h7.944A5.028 5.028 0 0125 29.098H7a5.028 5.028 0 015.028-5.028zM16 44s4-6.646 4-8.868c0-2.222-1.79-4.023-4-4.023s-4 1.801-4 4.023C12 37.354 16 44 16 44z"
            ></path>
        </svg>
    );
}
