import RocketIcon from "@components/svg/rocketIcon";
import React, { ReactElement } from "react";
import colors from "@styles/variables/vpnpricecomparisonVariables.module.scss";
import { hexToRGBA } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";
import AffiliateOfferUrl from "@components/shared/affiliateOfferUrl";
import { affiliateOfferZones } from "@lib/shared/config";

export default function WeStand(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <section
            style={{
                backgroundColor: hexToRGBA(colors.melon, 0.05),
                color: colors.navy,
            }}
            className="text-center md:max-w-3xl lg:max-w-7xl md:mx-auto  pt-12 lg:pt-16 pb-16 lg:pb-20 px-12 mb-11"
        >
            <RocketIcon className="mx-auto" />
            <h2 className="text-xl font-bold mt-4">
                We Stand for Internet Freedom & Privacy
            </h2>
            <p className="mt-3 font-light  lg:mx-auto">
                <span>
                    We've diligently partnered with the world's leading VPN
                    service providers, securing exclusive deals on their monthly
                    and yearly packages. Out of the 12 companies we've
                    collaborated with, CyberGhost stands out as offering the
                    highest overall value.
                </span>{" "}
                <AffiliateOfferUrl
                    zone={affiliateOfferZones.HOME_PAGE}
                    offer={domain?.defaultOffers[0]}
                    className="text-vpn-comparison-melon"
                    target="_blank"
                >
                    CyberGhost
                </AffiliateOfferUrl>{" "}
                <span>Offers the Highest Value Overall.</span>
            </p>
        </section>
    );
}
